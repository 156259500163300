import {
  Button,
  chakra,
  Flex,
  Grid,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import _, { repeat } from 'lodash';

function FindParentEducator({ data }) {
  const [currentCourse, setCurrentCourse] = useState('123-magic');
  const magicEducators = data.allContentfulParentEducator.nodes.filter(
    (educator) => educator.course === '123-magic-and-ec'
  );
  const groupedMagicEducators = _.groupBy(magicEducators, 'region');

  const nscEducators = data.allContentfulParentEducator.nodes.filter(
    (educator) => educator.course === 'nsc'
  );

  const groupedNscEducators = _.groupBy(nscEducators, 'region');

  const eaEducators = data.allContentfulParentEducator.nodes.filter(
    (educator) => educator.course === 'ea'
  );

  const groupedEaEducators = _.groupBy(eaEducators, 'region');

  return (
    <Flex flexDir="column">
      <Heading size="lg" mt={16}>
        Find a parent educator near you
      </Heading>
      <Text size="md" color="gray.500" mt={4}>
        Parentshop has trained professional practitioners that are now offering
        courses to parents like you across Australia. Below are the list of our
        professional partners for each course, please contact them directly for
        more information
      </Text>
      <Tabs mt={8} colorScheme="secondary">
        <TabList>
          <Tab onClick={() => setCurrentCourse('123-magic')}>
            1-2-3 Magic® & Emotion Coaching
          </Tab>
          <Tab onClick={() => setCurrentCourse('nsc')}>Anxiety Coach</Tab>
          <Tab onClick={() => setCurrentCourse('ea')}>Engaging Adolecents™</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            {Object.keys(groupedMagicEducators).map((area, index) => {
              if (!area) {
                return;
              }
              const educators = groupedMagicEducators[area];
              return (
                <Flex my={index === 0 ? 8 : 16} flexDir="column">
                  <Heading mb={6} size="lg" color="primary.500">
                    {area}
                  </Heading>
                  <Grid
                    gridTemplateColumns={{
                      base: '1fr',
                      lg: 'repeat(3, 1fr)',
                    }}
                    gap={12}
                    w="100%"
                  >
                    {educators.map((educator) => (
                      <Flex flexDir="column" w="100%">
                        <Heading size="md" color="gray.800" mb={2}>
                          {educator.location}
                        </Heading>
                        <Text>{educator.name}</Text>
                        <Text>{`${0}${educator.phone}`}</Text>
                        <Text>{educator.email}</Text>
                        {educator.website && (
                          <chakra.a
                            color="secondary.500"
                            fontWeight="semibold"
                            href={educator.website}
                            target="_blank"
                          >
                            {educator.website}
                          </chakra.a>
                        )}
                      </Flex>
                    ))}
                  </Grid>
                </Flex>
              );
            })}
          </TabPanel>
          <TabPanel px={0}>
            {Object.keys(groupedNscEducators).map((area, index) => {
              if (!area) {
                return;
              }
              const educators = groupedNscEducators[area];
              return (
                <Flex my={index === 0 ? 8 : 16} flexDir="column">
                  <Heading mb={6} size="lg" color="primary.500">
                    {area}
                  </Heading>
                  <Grid
                    gridTemplateColumns={{
                      base: '1fr',
                      lg: 'repeat(3, 1fr)',
                    }}
                    gap={12}
                    w="100%"
                  >
                    {educators.map((educator) => (
                      <Flex flexDir="column" w="100%">
                        <Heading size="md" color="gray.800" mb={2}>
                          {educator.location}
                        </Heading>
                        <Text>{educator.name}</Text>
                        <Text>{`${0}${educator.phone}`}</Text>
                        <Text>{educator.email}</Text>
                        {educator.website && (
                          <chakra.a
                            color="secondary.500"
                            fontWeight="semibold"
                            href={educator.website}
                            target="_blank"
                          >
                            {educator.website}
                          </chakra.a>
                        )}
                      </Flex>
                    ))}
                  </Grid>
                </Flex>
              );
            })}
          </TabPanel>
          <TabPanel px={0}>
            {Object.keys(groupedEaEducators).map((area, index) => {
              if (!area) {
                return;
              }
              const educators = groupedEaEducators[area];
              return (
                <Flex my={index === 0 ? 8 : 16} flexDir="column">
                  <Heading mb={6} size="lg" color="primary.500">
                    {area}
                  </Heading>
                  <Grid
                    gridTemplateColumns={{
                      base: '1fr',
                      lg: 'repeat(3, 1fr)',
                    }}
                    gap={12}
                    w="100%"
                  >
                    {educators.map((educator) => (
                      <Flex flexDir="column" w="100%">
                        <Heading size="md" color="gray.800" mb={2}>
                          {educator.location}
                        </Heading>
                        <Text>{educator.name}</Text>
                        <Text>{`${0}${educator.phone}`}</Text>
                        <Text>{educator.email}</Text>
                        {educator.website && (
                          <chakra.a
                            color="secondary.500"
                            fontWeight="semibold"
                            href={educator.website}
                            target="_blank"
                          >
                            {educator.website}
                          </chakra.a>
                        )}
                      </Flex>
                    ))}
                  </Grid>
                </Flex>
              );
            })}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default FindParentEducator;

export const query = graphql`
  query {
    allContentfulParentEducator {
      nodes {
        email
        course
        name
        phone
        region
        website
        location
      }
    }
  }
`;
